<template>
    <div class="upload__content">
        <!--   IF FILE UPLOAD     -->
        <div class="d-flex justify-content-between w-100 mr-4 ml-4" v-if="excelFile">
            <div class="d-flex">
                <span class="xls__logo">.XLS</span>
                <span class="d-flex flex-column ml-3">
            <span>{{ excelFile.name }}</span>
            <span>{{ size }}</span>
          </span>
            </div>

            <div class="edit__content">
                <input
                    type="file"
                    accept=".xlsx, .xls, .csv"
                    name="upload-excel"
                    class="upload__content-input"
                    @change="triggerUploadEvent"
                    ref="file-input"
                >
                <b-button class="edit__upload">
                    Заменить
                </b-button>
            </div>
        </div>

        <!--   FILE NOT UPLOAD YET     -->
        <span v-else class="d-flex justify-content-center align-items-center">
          <span class="arrow__down">
               <svg width="56" height="56" viewBox="0 0 24 24" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M4.38837 13.4153L11.054 19.6486C11.5555 20.1171 12.4429 20.1171 12.9462 19.6486L19.6119 13.4153C20.1312 12.9286 20.1294 12.1422 19.6065 11.6587C19.0837 11.1753 18.2407 11.1753 17.7196 11.6621L13.3322 15.7629V5.24169C13.3322 4.55462 12.7346 4 11.9983 4C11.2621 4 10.6645 4.55462 10.6645 5.24169V15.7629L6.27886 11.6621C6.01743 11.4187 5.67596 11.2978 5.33272 11.2978C4.99304 11.2978 4.65158 11.4187 4.39193 11.6587C3.87084 12.1422 3.86906 12.9286 4.38837 13.4153Z"
                                fill="#9CA3AF"/>
                        </svg>
          </span>
          <span class="d-flex flex-column">
              <span>
                {{ $t('drag_file') }}
              </span>
            <span style="color: #9CA3AF"> {{ $t('click_file') }}</span>
          </span>
        </span>

        <input
            v-if="!excelFile"
            type="file"
            accept=".xlsx, .xls, .csv"
            name="upload-excel"
            class="upload__content-input"
            @change="triggerUploadEvent"
            ref="file-input"
        >
    </div>
</template>

<script>
import {
    BButton,
} from 'bootstrap-vue'
import readExcelFile from "read-excel-file";

export default {
    name: "FileUploader",
    components: {
        BButton
    },
    data() {
        return {
            excelFile: null,
        }
    },
    computed: {
        size() {
            if (this.excelFile) {
                const kilobyte = this.excelFile.size / 1024
                if (kilobyte > 10) {
                    return Math.round(kilobyte / 1024) + ' МБ'
                }
                return Math.round(kilobyte) + ' КБ'
            }
            return 0
        }
    },
    methods: {
        triggerUploadEvent() {
            this.excelFile = this.$refs['file-input'].files[0]
        },
    }
}
</script>

<style lang="sass" scoped>
*
    font-style: normal
    color: #4B5563
    font-weight: 600

.max-width-16
    max-width: 16rem

.color-violet-600
    color: #4B5563

.upload__content
    width: 100%
    min-height: 12rem
    border-radius: 2rem
    margin-top: 1rem
    border: 3px dashed #D1D5DB
    background-color: #F3F4F6
    display: flex
    justify-content: center
    align-items: center
    position: relative

    .arrow__down
        margin-right: 2.5rem


    &-input
        outline: none
        cursor: pointer
        position: absolute
        margin: 0
        padding: 0
        width: 100%
        height: 100%
        opacity: 0

.download__template
    background-color: #F3F4F6
    margin-top: 1rem
    width: 100%

.add__button
    background: linear-gradient(88.25deg, #7C3AED 0%, #818CF8 100%)
    color: #FFFFFF

.edit__upload
    height: 100%
    background: #9CA3AF
    color: #4B5563
    border-radius: 2rem
    display: flex
    align-items: center
    position: relative
    padding: 1rem
    z-index: 1

.xls__logo
    border-radius: 1rem
    background-color: #9CA3AF
    text-transform: uppercase
    padding: 1rem 0.8rem
    color: white

.edit__content
    position: relative

    input
        z-index: 2

.upload__content:hover
    background: #E5E7EB
</style>