<template>
    <b-row>

        <h2 class="pl-1">{{ $t('roles_permission.titles.cards') }}</h2>

        <!--  BEFORE TABLE  -->
        <div class="d-flex justify-content-between col-12">
            <b-col
                md="4"
                class="my-1 px-0"
            >
                <b-form-group
                    class="mb-0"
                >
                    <b-input-group size="md">
                        <b-form-input
                            id="filterInput"
                            v-model="searchQuery"
                            type="search"
                            :placeholder="$t('reusable.search_placeholder')"
                        />
                        <b-input-group-append>
                            <b-button
                                :disabled="!searchQuery"
                                @click="searchQuery = ''"
                            >
                                {{ $t('reusable.clear_btn') }}
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>


            <div
                class="d-flex my-1 float-right"
                style="gap:.5rem"
            >
                <!--   IMPORT   -->

                <modal-button
                    v-if="createPermission"
                    ripple="rgb(243, 244, 246)"
                    size="lg"
                    name="import"
                    :modal-title="$t('import')"
                    :hide-footer="true"
                    class="import-body"
                    @btn-emit="createCard"
                    @open-emit="clearInputs"

                    ref="import-modal-button"
                >
                    <template v-slot:button
                              @click="createCard"
                              class="d-flex align-items-center"
                              style="gap:.5rem">
                        <span>
                            {{ $t('import') }}
                        </span>
                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M4.38837 13.4153L11.054 19.6486C11.5555 20.1171 12.4429 20.1171 12.9462 19.6486L19.6119 13.4153C20.1312 12.9286 20.1294 12.1422 19.6065 11.6587C19.0837 11.1753 18.2407 11.1753 17.7196 11.6621L13.3322 15.7629V5.24169C13.3322 4.55462 12.7346 4 11.9983 4C11.2621 4 10.6645 4.55462 10.6645 5.24169V15.7629L6.27886 11.6621C6.01743 11.4187 5.67596 11.2978 5.33272 11.2978C4.99304 11.2978 4.65158 11.4187 4.39193 11.6587C3.87084 12.1422 3.86906 12.9286 4.38837 13.4153Z"
                                fill="#28c76f"/>
                        </svg>

                    </template>

                    <template v-slot:modal-body>
                        <ValidationObserver ref="validation-observer">

                            <div class="d-flex flex-wrap" style="gap:2rem">

                                <p class="instruction">{{ $t('import') }}</p>

                                <FileUploader ref="file-upload"/>

                                <div class="d-flex justify-content-end w-100">
                                    <b-button @click="sendFile" variant="outline-success">
                                        {{ $t('import') }}
                                    </b-button>
                                </div>

                            </div>

                            <!--                            <b-overlay-->
                            <!--                                id="overlay-background"-->
                            <!--                                show-->
                            <!--                                variant="success"-->
                            <!--                                :opacity="0.5"-->
                            <!--                                :blur="blur"-->
                            <!--                                rounded="sm"-->
                            <!--                            >-->

                            <!--                            </b-overlay>-->

                        </ValidationObserver>
                    </template>

                </modal-button>

                <!--   CREATE   -->

                <modal-button
                    v-if="createPermission"
                    ripple="rgba(113, 102, 240, 0.15)"
                    size="lg"
                    name="modal-main"
                    :modal-title="$t('reusable.create')"
                    :modal-button="$t('reusable.create')"
                    @btn-emit="createCard"
                    @open-emit="clearInputs"
                    variant="success"
                    ref="create-modal-button"
                >
                    <template v-slot:button
                              @click="createCard"
                              class="d-flex align-items-center"
                              style="gap:.5rem">
                        <span>
                            {{ $t('reusable.create') }}
                        </span>
                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M18.6409 13.4167H13.499V18.514C13.499 19.3347 12.8279 20 12 20C11.1721 20 10.501 19.3347 10.501 18.514V13.4167H5.35909C4.58685 13.3374 4 12.6923 4 11.9226C4 11.153 4.58685 10.5079 5.35909 10.4286H10.4848V5.34731C10.5648 4.58176 11.2156 4 11.9919 4C12.7683 4 13.4191 4.58176 13.499 5.34731V10.4286H18.6409C19.4132 10.5079 20 11.153 20 11.9226C20 12.6923 19.4132 13.3374 18.6409 13.4167Z"
                                fill="#fff"/>
                        </svg>

                    </template>

                    <template v-slot:modal-body>
                        <ValidationObserver ref="validation-observer">
                            <div class="d-flex flex-wrap" style="gap:2rem">

                                <!-- RECIPIENT INFO -->

                                <div class="col-12 card__box-shadow">
                                    <div class="row">

                                        <b-col cols="4">
                                            <ValidationProvider :name="$t('recipient_first_name')" rules="required"
                                                                v-slot="{errors}">
                                                <b-form-group
                                                    :label="$t('recipient_first_name')"
                                                    label-for="recipient_first_name"
                                                >
                                                    <b-form-input
                                                        v-model="card.recipient_first_name"
                                                        id="recipient_first_name"
                                                        size="lg"
                                                        :placeholder="$t('recipient_first_name')"
                                                    />
                                                </b-form-group>
                                                <span class="validation__red">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </b-col>

                                        <b-col cols="4">
                                            <ValidationProvider :name="$t('recipient_last_name')" rules="required"
                                                                v-slot="{errors}">
                                                <b-form-group
                                                    :label="$t('recipient_last_name')"
                                                    label-for="recipient_last_name"
                                                >
                                                    <b-form-input
                                                        v-model="card.recipient_last_name"
                                                        id="recipient_last_name"
                                                        size="lg"
                                                        :placeholder="$t('recipient_last_name')"
                                                    />
                                                </b-form-group>
                                                <span class="validation__red">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </b-col>

                                        <b-col cols="4">
                                            <ValidationProvider :name="$t('recipient_primary_account_number')"
                                                                rules="required|min:16|max:16"
                                                                v-slot="{errors}">
                                                <b-form-group
                                                    :label="$t('recipient_primary_account_number')"
                                                    label-for="recipient_primary_account_number"
                                                >
                                                    <b-form-input
                                                        v-model="card.recipient_primary_account_number"
                                                        id="recipient_primary_account_number"
                                                        size="lg"
                                                        :placeholder="$t('recipient_primary_account_number')"
                                                    />
                                                </b-form-group>
                                                <span class="validation__red">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </b-col>

                                        <b-col cols="4">
                                            <ValidationProvider :name="$t('type')" rules="required"
                                                                v-slot="{errors}">

                                                <infinity-scroll-select
                                                    class="mb-1"
                                                    v-model="card.type_id"
                                                    apiUrl="cardType.getCardTypes"
                                                    :label="$t('type')"
                                                />

                                                <span class="validation__red">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </b-col>


                                        <!--  expiry_date-->

                                        <b-col cols="4">
                                            <ValidationProvider :name="$t('expiry_date')" rules="required"
                                                                v-slot="{errors}">
                                                <b-form-group
                                                    :label="$t('expiry_date')"
                                                    label-for="expiry_date"
                                                >
                                                    <base-date-picker
                                                        v-model="card.expiry_date"
                                                        :range="false"
                                                        format="YYYY-MM"
                                                        value-type="YYYY-MM"
                                                        :placeholder="$t('expiry_date')"
                                                    />
                                                </b-form-group>
                                                <span class="validation__red">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </b-col>

                                        <!--ABS_ID-->
                                        <b-col cols="4">
                                            <ValidationProvider :name="$t('abs_id')" rules="required"
                                                                v-slot="{errors}">
                                                <b-form-group
                                                    :label="$t('abs_id')"
                                                    label-for="abs_id"
                                                >
                                                    <b-form-input
                                                        v-model="card.abs_id"
                                                        id="abs_id"
                                                        size="lg"
                                                        :placeholder="$t('abs_id')"
                                                    />
                                                </b-form-group>
                                                <span class="validation__red">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </b-col>


                                        <!--PROCESSING_ID-->
                                        <b-col cols="4">
                                            <ValidationProvider :name="$t('processing_id')" rules="required"
                                                                v-slot="{errors}">
                                                <b-form-group
                                                    :label="$t('processing_id')"
                                                    label-for="abs_id"
                                                >
                                                    <b-form-input
                                                        v-model="card.processing_id"
                                                        id="processing_id"
                                                        size="lg"
                                                        :placeholder="$t('processing_id')"
                                                    />
                                                </b-form-group>
                                                <span class="validation__red">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </b-col>

                                    </div>

                                </div>


                                <!--  ALIASES  -->

                                <b-card
                                    :title="$t('specification')"
                                    class="mb-0 card_box-shadow col-12"
                                >

                                    <div class="d-flex flex-wrap align-items-center row"
                                         v-for="(option,index) in card.aliases"
                                         :key="index">

                                        <b-col cols="6">
                                            <b-form-group
                                                :label="$t('phone')"
                                                label-for="alias"
                                            >
                                                <b-form-input
                                                    v-model="option.alias"
                                                    size="lg"
                                                    id="alias"
                                                    :disabled="false"
                                                    :placeholder="$t('phone_placeholder')"
                                                />
                                            </b-form-group>
                                        </b-col>

                                        <b-col cols="6">
                                            <ValidationProvider :name="$t('is_default')" rules="required"
                                                                v-slot="{errors}">
                                                <b-form-group
                                                    :label="$t('is_default')"
                                                    :label-for="`is_default`+index"
                                                >
                                                    <b-form-checkbox
                                                        switch
                                                        v-model="card.aliases[index].is_default"
                                                        @change="changeDefault(index, $event)"
                                                        :id="`is_default`+index"
                                                        :label="$t('is_default')"
                                                        :placeholder="$t('is_default')"
                                                    />
                                                </b-form-group>
                                                <span class="validation__red">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </b-col>

                                    </div>

                                </b-card>


                                <!--  CARD INFO  -->

                                <div class="w-100">
                                    <b-button
                                        :class="visible ? null : 'collapsed'"
                                        :aria-expanded="visible ? 'true' : 'false'"
                                        aria-controls="collapse-4"
                                        variant="outline-primary"
                                        class="w-100 d-flex justify-content-between align-items-center"
                                        @click="visible = !visible"
                                    >
                                        {{ $t('not_required') }}
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M16.878 8.36302C17.3588 7.8817 18.138 7.8817 18.6353 8.34698C18.884 8.58764 19 8.89248 19 9.21336C19 9.5182 18.884 9.82303 18.6353 10.0476L12.8828 15.647C12.6507 15.8716 12.3357 16 12.0041 16C11.6726 16 11.3576 15.8716 11.1255 15.647L5.373 10.0476C4.87567 9.58237 4.87567 8.8283 5.373 8.34698C5.85376 7.8817 6.6495 7.8817 7.13025 8.36302L12.0041 13.096L16.878 8.36302Z"
                                                fill="#9CA3AF"/>
                                        </svg>
                                    </b-button>
                                    <b-collapse id="collapse-4" v-model="visible" class="mt-1">
                                        <div class="col-12 card__box-shadow">
                                            <div class="row">

                                                <b-col cols="4">
                                                    <ValidationProvider :name="$t('phone')"
                                                                        v-slot="{errors}">
                                                        <b-form-group
                                                            :label="$t('phone')"
                                                            label-for="contact_phone_number"
                                                        >
                                                            <b-form-input
                                                                v-model="card.contact_phone_number"
                                                                id="contact_phone_number"
                                                                size="lg"
                                                                :placeholder="$t('phone')"
                                                            />
                                                        </b-form-group>
                                                        <span class="validation__red">{{ errors[0] }}</span>
                                                    </ValidationProvider>
                                                </b-col>


                                                <b-col cols="4">
                                                    <ValidationProvider :name="$t('postal_code')"
                                                                        v-slot="{errors}">
                                                        <b-form-group
                                                            :label="$t('postal_code')"
                                                            label-for="postal_code"
                                                        >
                                                            <b-form-input
                                                                v-model="card.postal_code"
                                                                id="postal_code"
                                                                size="lg"
                                                                :placeholder="$t('postal_code')"
                                                            />
                                                        </b-form-group>
                                                        <span class="validation__red">{{ errors[0] }}</span>
                                                    </ValidationProvider>
                                                </b-col>

                                                <b-col cols="4">
                                                    <ValidationProvider :name="$t('city')"
                                                                        v-slot="{errors}">
                                                        <b-form-group
                                                            :label="$t('city')"
                                                            label-for="city"
                                                        >
                                                            <b-form-input
                                                                v-model="card.city"
                                                                id="city"
                                                                size="lg"
                                                                :placeholder="$t('city')"
                                                            />
                                                        </b-form-group>
                                                        <span class="validation__red">{{ errors[0] }}</span>
                                                    </ValidationProvider>
                                                </b-col>

                                                <b-col cols="4">
                                                    <ValidationProvider :name="$t('address')"
                                                                        v-slot="{errors}">
                                                        <b-form-group
                                                            :label="`${$t('address')} 1`"
                                                            label-for="address1"
                                                        >
                                                            <b-form-input
                                                                v-model="card.address1"
                                                                id="address1"
                                                                size="lg"
                                                                :placeholder="`${$t('address')} 1`"
                                                            />
                                                        </b-form-group>
                                                        <span class="validation__red">{{ errors[0] }}</span>
                                                    </ValidationProvider>
                                                </b-col>


                                                <b-col cols="4">
                                                    <ValidationProvider :name="$t('address')"
                                                                        v-slot="{errors}">
                                                        <b-form-group
                                                            :label="`${$t('address')} 2`"
                                                            label-for="address2"
                                                        >
                                                            <b-form-input
                                                                v-model="card.address2"
                                                                id="address2"
                                                                size="lg"
                                                                :placeholder="`${$t('address')} 2`"
                                                            />
                                                        </b-form-group>
                                                        <span class="validation__red">{{ errors[0] }}</span>
                                                    </ValidationProvider>
                                                </b-col>


                                                <b-col cols="4">
                                                    <ValidationProvider :name="$t('email_client')"
                                                                        v-slot="{errors}">
                                                        <b-form-group
                                                            :label="$t('email_client')"
                                                            label-for="account_email"
                                                        >
                                                            <b-form-input
                                                                v-model="card.account_email"
                                                                type="email"
                                                                id="account_email"
                                                                size="lg"
                                                                :placeholder="$t('email_client')"
                                                            />
                                                        </b-form-group>
                                                        <span class="validation__red">{{ errors[0] }}</span>
                                                    </ValidationProvider>
                                                </b-col>

                                                <b-col cols="4">
                                                    <ValidationProvider :name="$t('email_bank')"
                                                                        v-slot="{errors}">
                                                        <b-form-group
                                                            :label="$t('email_bank')"
                                                            label-for="contact_email"
                                                        >
                                                            <b-form-input
                                                                v-model="card.contact_email"
                                                                type="email"
                                                                id="contact_email"
                                                                size="lg"
                                                                :placeholder="$t('email_bank')"
                                                            />
                                                        </b-form-group>
                                                        <span class="validation__red">{{ errors[0] }}</span>
                                                    </ValidationProvider>
                                                </b-col>

                                            </div>

                                        </div>
                                    </b-collapse>
                                </div>


                            </div>
                        </ValidationObserver>
                    </template>

                </modal-button>

            </div>
        </div>

        <b-col cols="12">
            <b-table
                striped
                hover
                responsive
                sort-icon-left
                :busy="isBusy"
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
            >

                <template v-slot:head()="data">
                    {{ $t(`${data.label}`) }}
                </template>

                <template #table-busy>
                    <div class="text-center text-primary my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong class="ml-1">{{ $t('loading') }}</strong>
                    </div>
                </template>

                <template #cell(recipient_fullName)="data">
                    {{ fullName(data.item) }}
                </template>

                <template #cell(recipient_primary_account_number)="data">
                    <span style="white-space: nowrap">
                        {{ cardFormatter(data.item.recipient_primary_account_number) }}
                    </span>
                </template>

                <template #cell(issuer)="data">
                    {{ data.item.issuer_id }}
                </template>

                <!--                <template #cell(role)="data">-->
                <!--                    {{ data.item.role.name }}-->
                <!--                </template>-->


                <template #cell(crud_row)="data">
                    <div class="d-flex float-right">


                        <b-button v-if="viewLogsPermission" @click="viewCardLogs(data.item.id)" variant="outline">
                            <feather-icon
                                icon="ActivityIcon"
                                size="21"
                            />
                        </b-button>


                        <!--   EDIT   -->
                        <modal-button
                            v-if="editPermission"
                            class="crud__btn"
                            ripple="rgba(113, 102, 240, 0.15)"
                            size="lg"
                            @btn-emit="updateCardData(data.item.id, data.item)"
                            @open-emit="getOneCard(data.item.id)"
                            @close-emit="clearInputs"
                            :name="`modal-edit-${data.item.id}`"
                            :modal-button="$t('reusable.save_btn')"
                            :id="data.item.id"
                            :modal-title="$t('reusable.modal_edit_btn')"
                            variant="flat-warning"
                            button-variant="warning"
                            ref="update-modal-button"
                        >
                            <template
                                v-slot:button
                            >
                                <feather-icon
                                    icon="Edit2Icon"
                                    size="21"
                                />
                            </template>


                            <template v-slot:modal-body>
                                <ValidationObserver ref="validation-observer">
                                    <div class="d-flex flex-wrap" style="gap:2rem">

                                        <!-- RECIPIENT INFO -->

                                        <div class="col-12 card__box-shadow">
                                            <div class="row">

                                                <b-col cols="4">
                                                    <ValidationProvider :name="$t('recipient_first_name')"
                                                                        rules="required"
                                                                        v-slot="{errors}">
                                                        <b-form-group
                                                            :label="$t('recipient_first_name')"
                                                            label-for="recipient_first_name"
                                                        >
                                                            <b-form-input
                                                                v-model="card.recipient_first_name"
                                                                id="recipient_first_name"
                                                                size="lg"
                                                                :placeholder="$t('recipient_first_name')"
                                                            />
                                                        </b-form-group>
                                                        <span class="validation__red">{{ errors[0] }}</span>
                                                    </ValidationProvider>
                                                </b-col>

                                                <b-col cols="4">
                                                    <ValidationProvider :name="$t('recipient_last_name')"
                                                                        rules="required"
                                                                        v-slot="{errors}">
                                                        <b-form-group
                                                            :label="$t('recipient_last_name')"
                                                            label-for="recipient_last_name"
                                                        >
                                                            <b-form-input
                                                                v-model="card.recipient_last_name"
                                                                id="recipient_last_name"
                                                                size="lg"
                                                                :placeholder="$t('recipient_last_name')"
                                                            />
                                                        </b-form-group>
                                                        <span class="validation__red">{{ errors[0] }}</span>
                                                    </ValidationProvider>
                                                </b-col>

                                                <b-col cols="4">
                                                    <ValidationProvider :name="$t('recipient_primary_account_number')"
                                                                        rules="required|min:16|max:16"
                                                                        v-slot="{errors}">
                                                        <b-form-group
                                                            :label="$t('recipient_primary_account_number')"
                                                            label-for="recipient_primary_account_number"
                                                        >
                                                            <b-form-input
                                                                v-model="card.recipient_primary_account_number"
                                                                id="recipient_primary_account_number"
                                                                size="lg"
                                                                :placeholder="$t('recipient_primary_account_number')"
                                                            />
                                                        </b-form-group>
                                                        <span class="validation__red">{{ errors[0] }}</span>
                                                    </ValidationProvider>
                                                </b-col>

                                                <b-col cols="4">
                                                    <ValidationProvider :name="$t('type')" rules="required"
                                                                        v-slot="{errors}">

                                                        <infinity-scroll-select
                                                            class="mb-1"
                                                            v-model="card.type_id"
                                                            apiUrl="cardType.getCardTypes"
                                                            :label="$t('type')"
                                                        />

                                                        <span class="validation__red">{{ errors[0] }}</span>
                                                    </ValidationProvider>
                                                </b-col>


                                                <!--  expiry_date-->

                                                <b-col cols="4">
                                                    <ValidationProvider :name="$t('expiry_date')" rules="required"
                                                                        v-slot="{errors}">
                                                        <b-form-group
                                                            :label="$t('expiry_date')"
                                                            label-for="expiry_date"
                                                        >
                                                            <base-date-picker
                                                                v-model="card.expiry_date"
                                                                :range="false"
                                                                format="YYYY-MM"
                                                                value-type="YYYY-MM"
                                                                :placeholder="$t('expiry_date')"
                                                            />
                                                        </b-form-group>
                                                        <span class="validation__red">{{ errors[0] }}</span>
                                                    </ValidationProvider>
                                                </b-col>

                                                <!--ABS_ID-->
                                                <b-col cols="4">
                                                    <ValidationProvider :name="$t('abs_id')" rules="required"
                                                                        v-slot="{errors}">
                                                        <b-form-group
                                                            :label="$t('abs_id')"
                                                            label-for="abs_id"
                                                        >
                                                            <b-form-input
                                                                v-model="card.abs_id"
                                                                id="abs_id"
                                                                size="lg"
                                                                :placeholder="$t('abs_id')"
                                                            />
                                                        </b-form-group>
                                                        <span class="validation__red">{{ errors[0] }}</span>
                                                    </ValidationProvider>
                                                </b-col>


                                                <!--PROCESSING_ID-->
                                                <b-col cols="4">
                                                    <ValidationProvider :name="$t('processing_id')" rules="required"
                                                                        v-slot="{errors}">
                                                        <b-form-group
                                                            :label="$t('processing_id')"
                                                            label-for="abs_id"
                                                        >
                                                            <b-form-input
                                                                v-model="card.processing_id"
                                                                id="processing_id"
                                                                size="lg"
                                                                :placeholder="$t('processing_id')"
                                                            />
                                                        </b-form-group>
                                                        <span class="validation__red">{{ errors[0] }}</span>
                                                    </ValidationProvider>
                                                </b-col>

                                            </div>

                                        </div>


                                        <!--  ALIASES  -->

                                        <b-card
                                            :title="$t('specification')"
                                            class="mb-0 card_box-shadow col-12"
                                        >

                                            <div class="d-flex flex-wrap align-items-center row"
                                                 v-for="(option,index) in card.aliases"
                                                 :key="index">

                                                <b-col cols="6">
                                                    <b-form-group
                                                        :label="$t('phone')"
                                                        label-for="alias"
                                                    >
                                                        <b-form-input
                                                            v-model="option.alias"
                                                            size="lg"
                                                            id="alias"
                                                            :disabled="false"
                                                            :placeholder="$t('phone_placeholder')"
                                                        />
                                                    </b-form-group>
                                                </b-col>

                                                <b-col cols="6">
                                                    <ValidationProvider :name="$t('is_default')" rules="required"
                                                                        v-slot="{errors}">
                                                        <b-form-group
                                                            :label="$t('is_default')"
                                                            :label-for="`is_default`+index"
                                                        >
                                                            <b-form-checkbox
                                                                switch
                                                                v-model="card.aliases[index].is_default"
                                                                @change="changeDefault(index, $event)"
                                                                :id="`is_default`+index"
                                                                :label="$t('is_default')"
                                                                :placeholder="$t('is_default')"
                                                            />
                                                        </b-form-group>
                                                        <span class="validation__red">{{ errors[0] }}</span>
                                                    </ValidationProvider>
                                                </b-col>

                                            </div>

                                        </b-card>


                                        <!--  CARD INFO  -->

                                        <div class="w-100">
                                            <b-button
                                                :class="visible ? null : 'collapsed'"
                                                :aria-expanded="visible ? 'true' : 'false'"
                                                aria-controls="collapse-4"
                                                variant="outline-primary"
                                                class="w-100 d-flex justify-content-between align-items-center"
                                                @click="visible = !visible"
                                            >
                                                {{ $t('not_required') }}
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M16.878 8.36302C17.3588 7.8817 18.138 7.8817 18.6353 8.34698C18.884 8.58764 19 8.89248 19 9.21336C19 9.5182 18.884 9.82303 18.6353 10.0476L12.8828 15.647C12.6507 15.8716 12.3357 16 12.0041 16C11.6726 16 11.3576 15.8716 11.1255 15.647L5.373 10.0476C4.87567 9.58237 4.87567 8.8283 5.373 8.34698C5.85376 7.8817 6.6495 7.8817 7.13025 8.36302L12.0041 13.096L16.878 8.36302Z"
                                                        fill="#9CA3AF"/>
                                                </svg>
                                            </b-button>
                                            <b-collapse id="collapse-4" v-model="visible" class="mt-1">
                                                <div class="col-12 card__box-shadow">
                                                    <div class="row">

                                                        <b-col cols="4">
                                                            <ValidationProvider :name="$t('phone')"
                                                                                v-slot="{errors}">
                                                                <b-form-group
                                                                    :label="$t('phone')"
                                                                    label-for="contact_phone_number"
                                                                >
                                                                    <b-form-input
                                                                        v-model="card.contact_phone_number"
                                                                        id="contact_phone_number"
                                                                        size="lg"
                                                                        :placeholder="$t('phone')"
                                                                    />
                                                                </b-form-group>
                                                                <span class="validation__red">{{ errors[0] }}</span>
                                                            </ValidationProvider>
                                                        </b-col>


                                                        <b-col cols="4">
                                                            <ValidationProvider :name="$t('postal_code')"
                                                                                v-slot="{errors}">
                                                                <b-form-group
                                                                    :label="$t('postal_code')"
                                                                    label-for="postal_code"
                                                                >
                                                                    <b-form-input
                                                                        v-model="card.postal_code"
                                                                        id="postal_code"
                                                                        size="lg"
                                                                        :placeholder="$t('postal_code')"
                                                                    />
                                                                </b-form-group>
                                                                <span class="validation__red">{{ errors[0] }}</span>
                                                            </ValidationProvider>
                                                        </b-col>

                                                        <b-col cols="4">
                                                            <ValidationProvider :name="$t('city')"
                                                                                v-slot="{errors}">
                                                                <b-form-group
                                                                    :label="$t('city')"
                                                                    label-for="city"
                                                                >
                                                                    <b-form-input
                                                                        v-model="card.city"
                                                                        id="city"
                                                                        size="lg"
                                                                        :placeholder="$t('city')"
                                                                    />
                                                                </b-form-group>
                                                                <span class="validation__red">{{ errors[0] }}</span>
                                                            </ValidationProvider>
                                                        </b-col>

                                                        <b-col cols="4">
                                                            <ValidationProvider :name="$t('address')"
                                                                                v-slot="{errors}">
                                                                <b-form-group
                                                                    :label="`${$t('address')} 1`"
                                                                    label-for="address1"
                                                                >
                                                                    <b-form-input
                                                                        v-model="card.address1"
                                                                        id="address1"
                                                                        size="lg"
                                                                        :placeholder="`${$t('address')} 1`"
                                                                    />
                                                                </b-form-group>
                                                                <span class="validation__red">{{ errors[0] }}</span>
                                                            </ValidationProvider>
                                                        </b-col>


                                                        <b-col cols="4">
                                                            <ValidationProvider :name="$t('address')"
                                                                                v-slot="{errors}">
                                                                <b-form-group
                                                                    :label="`${$t('address')} 2`"
                                                                    label-for="address2"
                                                                >
                                                                    <b-form-input
                                                                        v-model="card.address2"
                                                                        id="address2"
                                                                        size="lg"
                                                                        :placeholder="`${$t('address')} 2`"
                                                                    />
                                                                </b-form-group>
                                                                <span class="validation__red">{{ errors[0] }}</span>
                                                            </ValidationProvider>
                                                        </b-col>


                                                        <b-col cols="4">
                                                            <ValidationProvider :name="$t('email_client')"
                                                                                v-slot="{errors}">
                                                                <b-form-group
                                                                    :label="$t('email_client')"
                                                                    label-for="account_email"
                                                                >
                                                                    <b-form-input
                                                                        v-model="card.account_email"
                                                                        type="email"
                                                                        id="account_email"
                                                                        size="lg"
                                                                        :placeholder="$t('email_client')"
                                                                    />
                                                                </b-form-group>
                                                                <span class="validation__red">{{ errors[0] }}</span>
                                                            </ValidationProvider>
                                                        </b-col>

                                                        <b-col cols="4">
                                                            <ValidationProvider :name="$t('email_bank')"
                                                                                v-slot="{errors}">
                                                                <b-form-group
                                                                    :label="$t('email_bank')"
                                                                    label-for="contact_email"
                                                                >
                                                                    <b-form-input
                                                                        v-model="card.contact_email"
                                                                        type="email"
                                                                        id="contact_email"
                                                                        size="lg"
                                                                        :placeholder="$t('email_bank')"
                                                                    />
                                                                </b-form-group>
                                                                <span class="validation__red">{{ errors[0] }}</span>
                                                            </ValidationProvider>
                                                        </b-col>

                                                    </div>

                                                </div>
                                            </b-collapse>
                                        </div>


                                    </div>
                                </ValidationObserver>
                            </template>


                        </modal-button>


                        <!--   DELETE   -->
                        <modal-button
                            v-if="deletePermission"
                            class="crud__btn"
                            ripple="rgba(113, 102, 240, 0.15)"
                            size="sm"
                            :name="`modal-delete-${data.item.id}`"
                            :modal-button="$t('reusable.modal_delete_btn')"
                            :modal-title="$t('reusable.modal_delete_btn')"
                            variant="flat-danger"
                            :id="data.item.id"
                            ref="delete-modal"
                            @btn-emit="deleteCard"
                        >
                            <template
                                v-slot:button
                            >
                                <feather-icon
                                    icon="Trash2Icon"
                                    size="21"
                                />
                            </template>

                            <template v-slot:modal-body>
                                <div class="my-2">
                                    {{ $t('modal.card_delete') }}
                                </div>
                            </template>

                        </modal-button>

                    </div>
                </template>
            </b-table>
        </b-col>

        <!--  PAGINATION  -->
        <b-col
            cols="12"
        >
            <b-pagination
                v-if="showPagination"
                v-model="pagination.current"
                :total-rows="pagination.totalItems"
                :per-page="pagination.perPage"
                align="center"
                size="sm"
                class="mt-0"
            ></b-pagination>
        </b-col>

    </b-row>
</template>

<script>
import {
    BTable,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BSpinner,
    BCard,
    BFormTextarea,
    BFormDatepicker,
    BOverlay,
    BCollapse
} from 'bootstrap-vue'
import ModalButton from "@/views/ui/modals/ModalButton";
import api from '@/services/api'
import Ripple from 'vue-ripple-directive'
import ToastNotification from '@core/components/toastification/ToastNotification.vue'
import InfinityScrollSelect from "@/views/ui/infinity-scroll/InfinityScrollSelect";
// import {debounce} from "@/util/helper";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import BaseDatePicker from "@/views/ui/BaseDatePicker";
import FileUploader from "@/views/ui/FileUploader";
import {mapActions, mapState} from 'vuex'
import CardPermission from "@/permission/card";


export default {
    name: "Cards",
    components: {
        BaseDatePicker,
        BTable,
        BBadge,
        BRow,
        BCol,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        BCollapse,
        BDropdown,
        BDropdownItem,
        BFormCheckbox,
        BSpinner,
        BCard,
        BFormTextarea,
        BFormDatepicker,
        BOverlay,
        ModalButton,
        ToastNotification,
        InfinityScrollSelect,
        DatePicker,
        FileUploader
    },
    directives: {
        Ripple
    },
    data() {
        return {
            card: {
                type_id: null,
                abs_id: null,
                processing_id: null,
                consent_date_time: null,
                country_id: null,
                recipient_primary_account_number: null,
                expiry_date: null,
                issuer_id: null,
                recipient_first_name: null,
                recipient_last_name: null,
                account_email: null,
                address1: null,
                address2: null,
                city: null,
                contact_email: null,
                contact_phone_number: null,
                postal_code: null,
                aliases: [
                    {
                        alias: null,
                        is_default: true,
                    }
                ],
            },
            visible: false,
            isBusy: false,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            searchQuery: this.$route.query.search,
            filterOn: [],
            fields: [
                {
                    key: 'id',
                    label: 'ID',
                },
                {
                    key: 'recipient_fullName',
                    label: 'recipient_fullName',
                },
                {
                    key: 'recipient_primary_account_number',
                    label: 'recipient_primary_account_number',
                },
                {
                    key: 'type_id',
                    label: 'type',
                },
                {
                    key: 'status',
                    label: 'status',
                },
                {
                    key: 'expiry_date',
                    label: 'expiry_date',
                },
                // {
                //     key: 'contact_phone_number',
                //     label: 'phone',
                // },
                {
                    key: 'crud_row',
                    label: ' ',
                },
            ],
            items: [],
            loading: false,
            pagination: {
                current: 1,
                perPage: 20
            },
            createPermission: CardPermission.getCardCreatePermission(),
            editPermission: CardPermission.getCardEditPermission(),
            deletePermission: CardPermission.getCardDeletePermission(),
            viewLogsPermission: CardPermission.getCardViewLogsPermission()
        }
    },
    watch: {
        '$route.query': {
            handler(query) {
                this.pagination.current = query.page
                this.getCards()
            },
            deep: true,
        },
        'pagination.current': {
            handler(page) {
                this.replaceRouter({
                    ...this.query,
                    page,
                })
            },
        },
        searchQuery: {
            handler(value) {
                if (value && value.length > 2) {
                    this.replaceRouter({
                        ...this.query,
                        search: value
                    })
                } else {
                    const hasSearchField = this.query.hasOwnProperty('search')
                    if (hasSearchField) {
                        delete this.query['search']
                        this.replaceRouter({
                            ...this.query,
                        })
                    }
                }
            },
        },
    },

    async created() {
        await this.getCards()
    },

    computed: {
        ...mapState('uploadFile', {
            finishFileImport: 'finishFileImport'
        }),
        query() {
            return Object.assign({}, this.$route.query)
        },

        hasItems() {
            return this.items.length > 0
        },

        showPagination() {
            return this.hasItems && !this.isBusy
        },

        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => ({
                    text: f.label,
                    value: f.key,
                }))
        },

    },

    methods: {
        ...mapActions('uploadFile', ['statusFile']),
        viewCardLogs(cardId) {
            this.$router.push({
                name: 'cardLogs', params: {id: cardId}
            })
        },
        cardFormatter(item) {
            const firstBlock = String(item).slice(0, 4)
            const secondBlock = String(item).slice(4, 6)
            const lastLine = String(item).slice(12, 16)
            return firstBlock + ' ' + secondBlock + '** **** ' + lastLine
        },
        downloadTemplate() {
            api.cards.importStatus().then(() => {
                localStorage.setItem('generateFile', 'true')
                this.statusFile(this)
            }).catch(() => {
                this.showToast('danger', 'Что-то пошло не так!', 'XIcon')
            })
        },
        sendFile() {
            const file = this.$refs['file-upload'].excelFile
            if (file) {
                const form = new FormData()
                form.append('file', file)
                api.cards.importCard(form).then(() => {
                    this.showToast('success', 'Успешно создано!', 'CheckIcon')
                }).catch((err) => {
                    this.showToast('danger', err.response.data.message, 'XIcon')
                })
            } else {
                this.showToast('danger', 'File yuklang', 'XIcon')
            }
            this.downloadTemplate()
        },
        changeDefault(index, event) {
            for (let i = 0; i < this.card.aliases.length; i++) {
                if (i === index) {
                    this.card.aliases[index].is_default = event
                } else {
                    this.card.aliases[i].is_default = false
                }
            }
        },
        fullName(item) {
            if (item && item.recipient_first_name && item.recipient_last_name) {
                return item.recipient_first_name + ' ' + item.recipient_last_name
            }
        },
        fullAddress(item) {
            // const country = await this.getCountryName(item.country_id)
            if (item && item.address1 && item.address2) {
                return item.country_id + ', ' + item.address1 + ', ' + item.address2
            }
        },
        showToast(variant, text, icon) {
            this.$toast({
                component: ToastNotification,
                props: {
                    title: text,
                    icon: icon,
                    variant,
                },
            })
        },

        replaceRouter(query) {
            this.$router.replace({query})
                .catch(() => {
                })
        },

        clearInputs() {
            this.card = {
                type_id: null,
                consent_date_time: null,
                country_id: null,
                recipient_primary_account_number: null,
                expiry_date: null,
                issuer_id: null,
                recipient_first_name: null,
                recipient_last_name: null,
                account_email: null,
                address1: null,
                address2: null,
                city: null,
                contact_email: null,
                contact_phone_number: null,
                postal_code: null,
                aliases: [
                    {
                        alias: null,
                        is_default: true,
                    }
                ],
            }
            this.visible = false
        },

        async getCards() {
            this.isBusy = true

            await api.cards.getCards(this.query)
                .then((res) => {
                    this.items = res.data.items
                    this.pagination = res.data.pagination
                })
                .catch(() => {
                    this.showToast('danger', 'Что-то пошло не так!', 'XIcon')
                })
                .finally(() => {
                    this.isBusy = false
                })
        },

        getOneCard(id) {
            this.loading = true
            api.cards.getCard(id)
                .then(res => {
                    this.card = res.data

                    for (const [key, value] of Object.entries(this.card)) {
                        if (key === 'aliases' && value.length) {
                            value.map((item, index) => {
                                this.card.aliases[index].is_default = !!item.is_default
                            })
                        }
                    }

                })
                .catch((error) => {
                    console.error(error)
                })
                .finally(() => {
                    this.loading = false
                })
        },

        async createCard() {
            const isValid = await this.$refs['validation-observer'].validate()
            if (isValid) {
                let data = this.card
                for (const [key, value] of Object.entries(data)) {
                    if (key === 'type_id') {
                        data[`${key}`] = value.id
                    }
                }
                await api.cards.createCard(data)
                    .then(() => {
                        this.$refs['create-modal-button'].closeModal()
                        this.showToast('success', 'Успешно создано!', 'CheckIcon')
                        this.getCards()
                    })
                    .catch((error) => {
                        if (error.response.data && error.response.data.errors) {
                            let errors = []
                            for (const [key, value] of Object.entries(error.response.data.errors)) {
                                errors.push(value[0])
                            }
                            errors.join(', ')
                            this.showToast('danger', errors[0], 'XIcon')
                        } else if (error.response.data && error.response.data.message) {
                            this.showToast('danger', error.response.data.message, 'XIcon')
                        } else {
                            this.showToast('danger', 'Что-то пошло не так!', 'XIcon')
                        }
                    })
            }
        },

        async updateCardData(id) {
            const isValid = await this.$refs['validation-observer'].validate()
            if (isValid) {

                const data = this.card
                for (const [key, value] of Object.entries(data)) {
                    if (key === 'country_id') {
                        data[`${key}`] = value.id
                    }
                    if (key === 'issuer_id') {
                        data[`${key}`] = value.id
                    }
                }

                for (const [key, value] of Object.entries(data)) {
                    if (key === 'aliases' && value.length) {
                        value.map((item, index) => {
                            data.aliases[index].is_default = item.is_default === 1
                            data.aliases[index].card_id = data.id
                        })
                    }
                }

                api.cards.updateCard(id, data)
                    .then(() => {
                        this.$refs['update-modal-button'].closeModal()
                        this.getCards()
                        this.clearInputs()
                        this.showToast('success', 'Успешно обновлено!', 'CheckIcon')
                    })
                    .catch((error) => {
                        if (error.response.data && error.response.data.errors) {
                            let errors = []
                            for (const [key, value] of Object.entries(error.response.data.errors)) {
                                errors.push(value[0])
                            }
                            errors.join(', ')
                            this.showToast('danger', errors[0], 'XIcon')
                        } else if (error.response.data && error.response.data.message) {
                            this.showToast('danger', error.response.data.message, 'XIcon')
                        } else {
                            this.showToast('danger', 'Что-то пошло не так!', 'XIcon')
                        }
                    })
            }
        },

        deleteCard(id) {
            api.cards.deleteCard(id).then(() => {
                this.$refs["delete-modal"].closeModal()
                this.showToast('success', 'Успешно удалено!', 'CheckIcon')
                this.getCards()
            }).catch((error) => {
                if (error.response.data && error.response.data.errors) {
                    let errors = []
                    for (const [key, value] of Object.entries(error.response.data.errors)) {
                        errors.push(value[0])
                    }
                    errors.join(', ')
                    this.showToast('danger', errors[0], 'XIcon')
                } else if (error.response.data && error.response.data.message) {
                    this.showToast('danger', error.response.data.message, 'XIcon')
                } else {
                    this.showToast('danger', 'Что-то пошло не так!', 'XIcon')
                }
            })
        },


        onFiltered(filteredItems) {
            // Trigger pagination to update the number of modals/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
    },
}
</script>

<style lang="scss">

[dir] .dropdown-item {
    padding: 0;
}

.validation__red {
    color: red;
    font-size: 12px;
    display: block;
    margin-bottom: 1rem;
}

.form-group {
    margin-bottom: 6px;
}

.crud__btn .btn {
    padding: 0.6rem;
}


[dir] .modal-body {
    padding: 2rem !important;
}

</style>
